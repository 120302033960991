<template>
  <div style="background-color:#f3f3f3;">
    <van-overlay :show="overlayShow">
      <van-loading  class="van-loading"/>
    </van-overlay>
    <div class="cont">
      <div class="top_title">
        你可以将常用的12个提醒添加到街道果果首页
        <br/>
        也可以长按拖动调整提醒的顺序
      </div>
      <div class="drag-list">
        <draggable v-model="dataList" :options="options">
          <div v-for="item in dataList" :key="item.id" class="drag-item">
            <p class="drag-item-count"><van-icon :name="item.icon ? setImgUrl(item.icon) : 'info'" color="#387FF5" size="0.4rem" class="drag-item-icon"/>{{item.count}}</p>
            <span class="drag-item-name">{{item.name}}</span>
            <van-icon name="clear" class="drag-item-close" color="#ccc" size="0.4rem" @click="delToList(item.id)"/>
          </div>
        </draggable>
      </div>
    </div>
    <div class="cont">
      <div class="top_title">
        <h3>
          全部提醒
        </h3>
      </div>
      <van-grid :column-num="4">
        <van-grid-item v-for="item in allDataList" :key="item.id" v-show="listShow(item.id)">
          <template >
            <van-icon name="add" class="drag-item-success" color="#387FF5" size="0.4rem" @click="addTOList(item)"/>
            <p class="drag-item-count">
              <van-icon :name="item.icon ? setImgUrl(item.icon) : 'info'" color="#387FF5" size="0.4rem" class="drag-item-icon"/>
              {{item.count}}</p>
            <span class="drag-item-name">{{item.name}}</span>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="btns">
      <van-col :span="24">
        <van-button type="info" size="large" square @click="submit">确认更改</van-button>
      </van-col>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {getImageStream} from '@/utils/index'
export default {
name: "manage-remind",
  components: {draggable},
  data () {
    return {
      overlayShow: false,
      options: {
        delayOnTouchOnly: true,  //开启触摸延时
        direction: 'vertical',   //拖动方向
        delay: 500,              //延时时长
        touchStartThreshold: 3,  //防止某些手机过于敏感(3~5 效果最好)
        chosenClass: 'chosen',    //选中之后拖拽项添加的class名(用于选中时候添加样式)
        animation: "300"
      },
      dataList: [],
      allDataList: []
    }
  },
  methods: {
    listShow (id) {
      return !this.dataList.some(item => item.id === id)
    },
    setImgUrl (path) {
      return getImageStream(path)
    },
    submit () {
      let ids = this.dataList.map(item => item.id)
      // if (ids.length !== 8) {
      //   return this.$toast.fail('请选择八个提醒')
      // }
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/message/remind/config/save'),
        method: 'post',
        data: this.$http.adornData(ids, false),
        params: this.$http.adornParams({
          type: 1
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$toast.success('修改成功')
          setTimeout(() => {
            this.$router.back()
          }, 1000)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getAllList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/message/remind/config/list'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.allDataList = data.allList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getDataList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/message/remind/config/myList'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId
        })
      }).then(({data}) => {
        this.overlayShow = false
        if (data && data.code === 0) {
          this.dataList = data.myList
        } else {
          this.$toast.fail(data.msg)
        }
      })
      this.getAllList()
    },
    addTOList (val) {
     let flag =  this.dataList.some(item => item.id === val.id )
      if (flag || this.dataList.length >= 12) {
        return this.$toast.fail('列表已满或该项已在列表中!!!')
      }
      this.dataList.push(val)
    },
    delToList (id) {
      this.dataList.forEach((item, index) => {
        if (item.id === id) {
          this.dataList.splice(index, 1)
        }
      })
    }
  },
  created() {
    this.overlayShow = true
    this.getDataList()
  }
}
</script>

<style scoped lang="scss">
::v-deep .van-grid-item__content {
  padding: 30px 0 10px 0;
}
.van-loading {
  position: absolute;
  top: 50%;
  left: 50%;
}
.drag-list {
  /*防止长按系统默认弹窗*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  :nth-child(4), :nth-child(8), :nth-child(12){
    border-right: none;
  }
}
.drag-item {
  position: relative;
  display: inline-block;
  background-color: #fff;
  height: 120px;
  width: 25%;
  line-height: 60px;
  text-align: center;
  margin-top: -1px;
  border-top: 1px solid #ebedf0;
  border-bottom: 1px solid #ebedf0;
  border-right: 1px solid #ebedf0;
  padding: 30px 0 0 0 ;
}
.drag-item-count {
  margin: 0;
  font-size: 32px;
  line-height: 32px;
  height: 32px;
}
.drag-item-name {
  display: inline-block;
  font-size: 26px;
  line-height: 50px;
  color: #9e9d9d;
}
.drag-item-icon {
  margin-top: -10px;
  margin-right: 5px;
}
.drag-item-close, .drag-item-success {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}
.chosen {
  background-color: #ccc;
}
.cont{
  margin-top: 30px;
  background-color: #fff;
  .top_title {
    text-align: center;
    font-size: 26px;
    height: 100px;
    line-height: 35px;
    padding-top: 20px;
    h3 {
      margin: 15px 0 ;
      font-weight: 800;
    }
  }
}
</style>
